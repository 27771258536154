import { GMAPS_KEY, MAP_ID } from '../config/variables';

const loadGmapApi = (options) => {
    if (typeof document === 'undefined') {
        // Do nothing if run from server-side
        return;
    }
    if (!window.google) {
        const googleMapScript = document.createElement('SCRIPT');

        // Allow options to be an object.
        // This is to support more esoteric means of loading Google Maps,
        // such as Google for business
        // https://developers.google.com/maps/documentation/javascript/get-api-key#premium-auth
        if (typeof options !== 'object') {
            throw new Error('options should  be an object');
        }

        const opts = {
            libraries: options.libraries.join(','),
            callback: 'vueGoogleMapsInit',
            key: GMAPS_KEY,
        };

        const baseUrl = 'https://maps.googleapis.com/';

        const url = `${baseUrl}maps/api/js?${
            Object.keys(opts)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(opts[key])}`+'&v=beta'+'&map_ids='+MAP_ID)
                .join('&')
        }`;

        googleMapScript.setAttribute('src', url);
        googleMapScript.setAttribute('async', '');
        googleMapScript.setAttribute('defer', '');
        document.head.appendChild(googleMapScript);
    } else {
        throw new Error('You already started the loading of google maps');
    }
};

export default function makeGmapApiPromiseLazy() {
    // Things to do once the API is loaded
    function onApiLoaded() {
        return window.google;
    }

    return new Promise((resolve, reject) => {
        try {
            if (!window.google) {
                window.vueGoogleMapsInit = resolve;
                loadGmapApi({
                    libraries: ['geometry'],
                });
            } else {
                resolve(window.google);
            }
        } catch (err) {
            reject(err);
        }
    }).then(onApiLoaded);
}

