<template>
    <div class="littleWindow" v-bind:class="{enlarge: biggerWindow}">
        <div class="row">
            <div id="imageCarousel" class="carousel slide" data-bs-ride="carousel"
                 v-if="selectedRoad !== null || images !== undefined">
                <ol class="carousel-indicators">
                    <li data-bs-target="#imageCarousel" v-for="{id} in images" :key="id" :data-bs-slide-to="id"
                        :class="{ 'active': id === 0 }"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item" v-for="{id, url} in images" :key="id" :class="{ 'active': id === 0 }">
                        <div class="expandWrapper">
                            <a href="#" type="button" @click="expandWindow" class="expandButton"></a>
                        </div>
                        <img :src="url" :class="selectedRoad.name" alt="{{selectedRoad.name}} {{id}}"/>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#imageCarousel" role="button" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </a>
                <a class="carousel-control-next" href="#imageCarousel" role="button" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </a>
            </div>
        </div>
        <div class="whiteSection pt-2">
            <div class="row">
                <div class="col-9">
                    <!-- FILTERI -->
                    <div class="row">
                        <div class="col-12">
                            <!-- FOR PETLJA ZA FILTERE LOKACIJE -->
                            <span>EXTREME</span>
                            <span>CARS</span>
                            <span>NATURE</span>
                        </div>
                        <div class="col-12 pb-3">
                            <h5 class="pt-1"> {{ selectedRoad.name }} </h5>
                            <svg focusable="false" data-prefix="fas" data-icon="map-marker-alt"
                                 class="navIcon svg-inline--fa fa-map-marker-alt fa-w-12" role="img"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path fill="currentColor"
                                      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                            </svg>
                            <span v-if="distance!==null">{{ distance.toFixed(2) }} km</span>
                        </div>
                    </div>
                </div>
                <div class="col-3 pt-3">
                    <a href="#" @click="addToList">
                        <svg v-bind:class="{show: filledIcon}" class="myList" xmlns="http://www.w3.org/2000/svg"
                             width="25" height="22" viewBox="0 0 25 22">
                            <defs>
                                <clipPath id="clip-Like_-_off">
                                    <rect width="25" height="22"/>
                                </clipPath>
                            </defs>
                            <g id="Like_-_off" data-name="Like - off" clip-path="url(#clip-Like_-_off)">
                                <g id="Path_1264" data-name="Path 1264" transform="translate(-333.937 69.04)">
                                    <path id="Path_1274" data-name="Path 1274"
                                          d="M346.756-48.305l-1.4-.007-10.121-10.06-.295-.709v-4.808l.447-.833,4.966-3.3,1.234.1,4.458,4.144,4.318-4.134,1.218-.129,5.333,3.3.474.85v4.808l-.3.717ZM336.937-59.5l9.127,9.073,9.321-9.078v-3.83l-4.2-2.6-4.441,4.25-1.372.011L340.8-65.919l-3.863,2.566Z"
                                          fill="#1aa1ed"/>
                                </g>
                            </g>
                        </svg>

                        <svg v-bind:class="{show: !filledIcon}" class="myList" xmlns="http://www.w3.org/2000/svg"
                             width="25" height="22" viewBox="0 0 25 22">
                            <defs>
                                <clipPath id="clip-Like_-_on">
                                    <rect width="25" height="22"/>
                                </clipPath>
                            </defs>
                            <g id="Like_-_on" data-name="Like - on" clip-path="url(#clip-Like_-_on)">
                                <g id="Path_1264" data-name="Path 1264" transform="translate(-333.937 69.04)">
                                    <path id="Path_1274" data-name="Path 1274"
                                          d="M346.756-48.305l-1.4-.007-10.121-10.06-.295-.709v-4.808l.447-.833,4.966-3.3,1.234.1,4.458,4.144,4.318-4.134,1.218-.129,5.333,3.3.474.85v4.808l-.3.717Z"
                                          fill="#1aa1ed"/>
                                </g>
                            </g>
                        </svg>

                        <p class="pt-1">My List</p>
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="inlineButtons">
                        <a href="tel:{{'selectedRoad.phone'}}" class="infoButton">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12"
                                 viewBox="0 0 13 12">
                                <defs>
                                    <clipPath id="clip-Call">
                                        <rect width="13" height="12"/>
                                    </clipPath>
                                </defs>
                                <g id="Call" clip-path="url(#clip-Call)">
                                    <g id="phone" transform="translate(0.929 0.461)">
                                        <path id="Path_972" data-name="Path 972"
                                              d="M13.071,7.69V4c0,.013-.275,0-4.305,0,.007.018.028,3.684,0,3.69h1.845a4.92,4.92,0,0,1-4.92,4.92V10.765c-.008.01-3.676-.006-3.69,0,.013-.013-.013,4.271,0,4.305-.018-.02,3.673-.026,3.69,0A7.381,7.381,0,0,0,13.071,7.69Z"
                                              transform="translate(-2 -4)" fill="#707070"/>
                                    </g>
                                </g>
                            </svg>

                            Call
                        </a>
                        <a href="mailto:{{'selectedRoad.email'}}" class="infoButton">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13"
                                 viewBox="0 0 18 13">
                                <defs>
                                    <clipPath id="clip-Email">
                                        <rect width="18" height="13"/>
                                    </clipPath>
                                </defs>
                                <g id="Email" clip-path="url(#clip-Email)">
                                    <path id="Icon_zocial-email" data-name="Icon zocial-email"
                                          d="M.072,14.094V5.042q0-.016.047-.3L5.446,9.3.135,14.408a1.332,1.332,0,0,1-.063-.314ZM.779,4.115a.677.677,0,0,1,.267-.047H15.393a.89.89,0,0,1,.283.047L10.333,8.688l-.707.566L8.228,10.4l-1.4-1.147-.707-.566ZM.795,15.021,6.153,9.882l2.074,1.681L10.3,9.882l5.359,5.139a.754.754,0,0,1-.267.047H1.046a.712.712,0,0,1-.251-.047ZM11.009,9.3l5.311-4.557a.938.938,0,0,1,.047.3v9.051a1.2,1.2,0,0,1-.047.314Z"
                                          transform="translate(0.928 -3.068)" fill="#707070"/>
                                </g>
                            </svg>

                            Email
                        </a>
                        <a :href="directionLink" class="infoButton" target="_blank" @click="calculateRoute">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17"
                                 viewBox="0 0 18 17">
                                <defs>
                                    <clipPath id="clip-Directions">
                                        <rect width="18" height="17"/>
                                    </clipPath>
                                </defs>
                                <g id="Directions" clip-path="url(#clip-Directions)">
                                    <path id="Path_1275" data-name="Path 1275"
                                          d="M9.881,1.707c.047-.059-7.98,7.985-7.99,8.049-.015.009,7.981,8.063,7.99,8.148-.052.044,7.968-8.157,7.93-8.148Zm1.636,10.2V9.928H8.345v2.379H6.759V9.135a.8.8,0,0,1,.793-.793h3.965V6.359l2.776,2.776Z"
                                          transform="translate(-0.997 -0.998)" fill="#707070"/>
                                </g>
                            </svg>

                            Directions
                        </a>
                    </div>
                </div>
            </div>
            <div class="row pt-4" v-if="biggerWindow">
                <div class="col-12">
                    <p class="locationText" v-html="selectedRoad.description"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Road from '@/models/Road';

export default {
    data() {
        return {
            roadID: null,
            selectedRoad: [],
            distance: null,
            locationLat: null,
            locationLong: null,
            pathLine: null,
            destination: [],
            isCalculateClicked: false,
            images: [],
            biggerWindow: false,

            userList: [],
            listItemExists: null,
            filledIcon: false,

            routeLink: "",
            origin: "",
            destinationPoint: "",
            travelMode: "",
            directionLink: "",
        }
    },
    mounted() {
        this.images = [];
        this.manageWindow();
    },
    methods: {
        async manageWindow() {
            this.filledIcon = false;
            this.roadID = this.$store.getters.getSelectedRoad;
            this.userList = this.$store.getters.getCheckpointList;
            //this.selectedRoad = this.$store.getters.roads(this.roadID);

            if (this.userList === undefined) {
                this.filledIcon = false;
            } else if (this.userList.includes(this.roadID) === true) {
                this.filledIcon = true;
            }

            if (this.roadID !== null) {
                this.selectedRoad = await Road.find(this.roadID);

                for (let i = 0; i < this.selectedRoad.images.length; i++) {
                    this.images[i] = {
                        id: i,
                        url: this.selectedRoad.images[i].url
                    }
                }

                this.locationLat = this.$store.getters.getLocation.lat;
                this.locationLong = this.$store.getters.getLocation.lng;

                // eslint-disable-next-line no-undef
                this.distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(this.locationLat, this.locationLong), new google.maps.LatLng(this.selectedRoad.lat, this.selectedRoad.long));
                this.distance /= 1000;
                this.isCalculateClicked = !this.isCalculateClicked;
            }
        },
        calculateRoute() {
            this.isCalculateClicked = !this.isCalculateClicked;
            if (this.isCalculateClicked === true) {
                this.destination = [{
                    lat: this.selectedRoad.lat,
                    lng: this.selectedRoad.long
                }]
                this.directionLink = "https://www.google.com/maps/dir/?api=1&";
                this.origin = "origin=" + this.locationLat + "+" + this.locationLong;
                this.destinationPoint = "&destination=" + this.selectedRoad.lat + "+" + this.selectedRoad.long;
                this.travelMode = "&travelmode=driving";
                this.directionLink = this.directionLink + this.origin + this.destinationPoint + this.travelMode;
            }
        },
        expandWindow() {
            this.biggerWindow = !this.biggerWindow;
        },
        addToList() {
            this.userList = this.$store.getters.getCheckpointList;
            if (this.userList === undefined) {
                this.$store.dispatch('setCheckpointList', this.roadID);
                this.filledIcon = false;
            } else {
                this.listItemExists = this.userList.includes(this.roadID);
                if (this.listItemExists === true) {
                    this.$store.dispatch('removeCheckpointList', this.roadID);
                    this.filledIcon = false;
                } else {
                    this.$store.dispatch('setCheckpointList', this.roadID);
                    this.filledIcon = true;
                }
            }
        },

    },
    watch: {
        "$store.getters.getSelectedRoad": {
            handler: function () {
                this.manageWindow();
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.littleWindow.enlarge {
    max-height: 550px;
    min-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;

    @media screen and (min-width: 768px) {
        max-height: 60vh;
        min-height: 330px;
    }
}

.littleWindow {
    position: absolute;
    bottom: 14px;
    width: 96%;
    right: 2%;
    left: 2%;
    background-color: white;
    z-index: 100;

    @media screen and (min-width: 500px) {
        width: 400px;
        margin: auto;
        display: block;
    }

    @media screen and (min-width: 768px) {
        bottom: 0;
        right: auto;
        left: 80px;
    }

    @media screen and (min-width: 1025px) {
        height: auto;
    }

    #imageCarousel {
        width: 100%;
        height: 140px;
        //padding: 0;

        @media screen and (min-width: 768px) {
            height: 170px;
        }

        .carousel-indicators {
            li {
                width: 8px;
                height: 8px;
                margin-bottom: -16px;
            }
        }

        .carousel-item {
            img {
                width: 100%;
                max-height: 140px;
                object-fit: cover;

                @media screen and (min-width: 768px) {
                    max-height: 170px;
                    height: 170px;
                }
            }

            .expandWrapper {
                position: absolute;
                left: 50%;
                top: -6px;

                .expandButton {
                    border: none;
                    background-color: rgba(255, 255, 255, 0.5);
                    padding: 2px 55px;
                    position: relative;
                    left: -50%;
                }
            }
        }

    }

    .whiteSection {
        padding: calc(20px + (40 - 20) * (100vw - 600px) / (1200 - 600));

        @media screen and (min-width: 1025px) {
            padding: 10px;
        }


        span {
            margin-right: 14px;
            color: #bebebe;
            font-size: 14px;
        }

        .navIcon {
            width: 12px;
            height: auto;
            fill: #bebebe;
            color: #bebebe;
            margin-right: 10px;
        }

        a {
            text-decoration: none;

            .myList {
                width: 30px;
                height: auto;
                color: #00A1ED;
                display: block;
                margin: auto;
            }

            .myList.show {
                display: none;
            }

            p {
                display: block;
                margin: auto;
                text-align: center;
                color: #707070;
            }
        }

        h5 {
            color: #707070;
            font-weight: 500;
            margin-bottom: 2px;
            font-size: 1.2rem;
            font-family: "Roboto", sans-serif;
        }

        .inlineButtons {
            width: 100%;

            .infoButton {
                background-color: #EBEBEB;
                color: #707070;
                padding: 10px 12px;
                text-decoration: none;
                margin-right: 12px;

                svg {
                    fill: #707070;
                    width: 20px;
                    height: auto;
                }
            }

            @media screen and (max-width: 373px) {
                .infoButton {
                    padding: 10px 10px;
                    margin-right: 8px;
                }
            }

            @media screen and (max-width: 359px) {
                .infoButton {
                    font-size: 14px;
                }
            }
        }

        .highlight {
            color: #00D2ED;
            font-weight: 600;
            margin-right: 4px;
            font-size: 16px;
        }

        .locationText {
            text-align: left;
        }
    }
}
</style>
